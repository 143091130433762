import { PublicKey } from "@solana/web3.js";
import './SolAirdrop.css';
import React, { useState, useEffect } from 'react';

function SolAirdrop() {
  const [list, setList] = useState([]);
  const [list2, setList2] = useState([]);
  const [list3, setList3] = useState([]);
  const [list4, setList4] = useState([]);
  const [list5, setList5] = useState([]);
  const [list6, setList6] = useState([]);
  const [list7, setList7] = useState([]);
  const [solanaPublicKeys, setSolanaPublicKeys] = useState("");
  const [publicKeyList, setPublicKeyList] = useState([]);
  const [isAirdropped, setIsAirdropped] = useState(false);
  const [foundKeys, setFoundKeys] = useState([]);
  const [countdown, setCountdown] = useState(calculateCountdown());

  useEffect(() => {
    import('./list.mjs').then((module) => {
      setList(module.list);
    });
    import('./list2.mjs').then((module) => {
      setList2(module.list2);
    });
    import('./list3.mjs').then((module) => {
      setList3(module.list3);
    });
    import('./list4.mjs').then((module) => {
      setList4(module.list4);
    });
    import('./list5.mjs').then((module) => {
      setList5(module.list5);
    });
    import('./list6.mjs').then((module) => {
      setList6(module.list6);
    });
    import('./list7.mjs').then((module) => {
      setList7(module.list7);
    });

    const intervalId = setInterval(() => {
      setCountdown(calculateCountdown());
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  function calculateCountdown() {
    const targetDate = new Date("January 29, 2024 00:00:00").getTime();
    const currentDate = new Date().getTime();
    const timeRemaining = targetDate - currentDate;

    const days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);

    return `${days}d ${hours}h ${minutes}m ${seconds}s`;
  }

  async function onSubmit(e) {
    e.preventDefault();
    const publicKeyList = solanaPublicKeys.split("\n").map((address) => address.trim());
    setPublicKeyList(publicKeyList);

    let found = [];
    for (const solanaPublicKey of publicKeyList) {
      if (list7.includes(solanaPublicKey) || list.includes(solanaPublicKey) || list2.includes(solanaPublicKey) || list3.includes(solanaPublicKey) || list4.includes(solanaPublicKey) || list5.includes(solanaPublicKey) || list6.includes(solanaPublicKey)) {
        found.push(`FOUND: ${solanaPublicKey}`);
      }
    }
    setFoundKeys(found);
  }

  return (
    <div className="container">
      <div className="countdown-timer">{countdown}</div>
      <form onSubmit={onSubmit}>
        <label htmlFor="search">FASTEST WEN TOKEN AIRDROP CHECKER</label>
        <div className="donation-address"><span className="bright-green-text">Discord: 0xPauly</span></div>
        <p className="warning">
          <b>Enter up to 129409124901409 solana addresses, one per line</b>
        </p>
        <textarea
          name="search"
          placeholder="One address per line, no commas..."
          id="search"
          value={solanaPublicKeys}
          onChange={(e) => setSolanaPublicKeys(e.target.value)}
          className={isAirdropped ? "claimed" : ""}
        />
        <button type="submit">WEN?</button>
      </form>
      <div className="donation-address">definitely not accepting donations at: <span className="bright-green-text2">   2955Z9RTa6nSCWmVBBoYJULvcvPfbSHb1Mo8E4eQgew2</span></div>
      <div className="footer-text">{list.filter(pubkey => publicKeyList.includes(pubkey)).map((pubkey, index) => <div key={index} className="bright-green-text">{pubkey}</div>)}</div>
      <div className="found-keys">
        {foundKeys.map((key, index) => (
          <div key={index}>{key}</div>
        ))}
      </div>
    </div>
  );
}

export default SolAirdrop;